import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Footer.css';
import facebook_img from '../../../imgs/facebook.svg'
import youtube_img from '../../../imgs/youtube.svg'
import DivElement from './RightDiv/Div'

class RightDiv extends Component {
    state = {
        facebook: {
            image_uri: facebook_img,
            content: <p>
                        facebook.com/computervisionvietnam
                    </p>
        },
        youtube: {
            image_uri: youtube_img,
            content: <p>
                        youtube.com/channel/uc-los121
                    </p>
        }
    }

    render() { 
        return (
            <div className="footer-text-div">
                <div className="footer-text-right-div">
                    <p className="footer-p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra amet, in amet amet ornare condimentum mattis.
                        Cursus ultricies purus pharetra, accumsan ipsum bibendum in. Tellus fames id donec feugiat scelerisque suscipit a.
                    </p>
                </div>
                <div>
                    {Object.keys(this.state).map((item, index) => {
                            return(
                                <DivElement key={index} image={this.state[item].image_uri} content={this.state[item].content}/>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
 
export default RightDiv;