import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Block3.css';
import cv_1 from '../../../../imgs/small_cv_sample1.png'
import cv_2 from '../../../../imgs/small_cv_sample2.png'
import cv_3 from '../../../../imgs/small_cv_sample3.png'
import cv_4 from '../../../../imgs/small_cv_sample4.png'
import cv_5 from '../../../../imgs/small_cv_sample5.png'

class CVSamples extends Component {
    render() { 
        return (
            <div className="block3-cv-div-cover">
                <div>
                    <img className="block3-cv-sample" src={cv_1} alt="CV 1"/>
                </div>
                <div>
                    <img className="block3-cv-sample" src={cv_2} alt="CV 1"/>
                </div>
                <div>
                    <img className="block3-cv-sample" src={cv_3} alt="CV 1"/>
                </div>
                <div>
                    <img className="block3-cv-sample" src={cv_4} alt="CV 1"/>
                </div>
                <div>
                    <img className="block3-cv-sample" src={cv_5} alt="CV 1"/>
                </div>
            </div>
        );
    }
}
 
export default CVSamples;