import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Block4.css';
import Content from './Elements/Content';

class Block4 extends Component {
    render() { 
        return (
            <div className="block4-outter-div">
                <Content />
            </div>
        );
    }
}
 
export default Block4;