import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Block1.css'
import image from '../../../../imgs/block1_img.png'

class RightInnerDiv extends Component {
    render() { 
        return (
            // eslint-disable-next-line
            <div className="block1-right-div">
                <img className="block1-image" src={image} alt="Block1"/>
            </div>
        );
    }
}
 
export default RightInnerDiv;