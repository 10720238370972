import React, { useState } from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import './CV.css';
import 'bootstrap/dist/css/bootstrap.min.css'

const CVScan = (props) => {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [open, setOpen] = useState(false);
    const cv_upload_data = props.cv_upload_data;

    document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
    });

    var onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    var changePage = (offset) => {
        setPageNumber(previousPage => {
            return previousPage + offset
        });
    }

    var nextPage = () => {
        changePage(1);
    }

    var previousPage = () => {
        changePage(-1);
    }
    return(
        <div>
            <h2 className="block3-text">Kết Quả</h2>
            <div className="outter-div-cv">
                <div className="inner-div-display-cv">
                    <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="display-same-line">
                            <div className="text">
                                Page {pageNumber}
                            </div>
                            <div>
                                <button className="pdf-cv-demo-butt" type="button" disabled={pageNumber<=1} onClick={previousPage}> Previous </button>
                            </div>
                            <div>
                                <button className="pdf-cv-demo-butt" type="button" disabled={pageNumber>=numPages} onClick={nextPage}> Next </button>
                            </div>
                    </div>
                </div>
                <div className="inner-div-display-result">
                    {Object.keys(cv_upload_data).map((item, index) => {
                        var data = cv_upload_data[item];
                        return(
                            <div>
                                <Button
                                    className="div-display-result display-result"
                                    onClick={() => setOpen(!open)}
                                    aria-controls={index}
                                    aria-expanded={open}>
                                        {item}
                                </Button>
                                <Collapse in={open}>
                                    <div id={index} className="collapse-div">
                                        {
                                            Object.keys(data).map((meta_item) => {
                                                if (item === "INFORMATION") {
                                                    var data_1 = data[meta_item];
                                                    return(
                                                        <div>
                                                            <div className="small-title">
                                                                {meta_item}
                                                            </div>
                                                            <p className="margin_bottom_top"> - {data_1}</p>
                                                        </div>
                                                    )
                                                }
                                                var data_1 = data[meta_item];
                                                if(typeof data_1 === "object"){
                                                    return(
                                                        <div>
                                                            <div className="small-title">
                                                                {meta_item}
                                                            </div>
                                                            {
                                                                data_1.map(data_2 => {
                                                                    var data_list = data_2.split("|")
                                                                    return(
                                                                        <div>
                                                                            <div className="time-div">
                                                                                {data_list[1]}
                                                                            </div>
                                                                            <p className="margin_bottom_top">- {data_list[0]}</p>
                                                                        </div>
                                                                    )
                                                                }) 
                                                            }
                                                        </div>
                                                    )
                                                }
                                                return(
                                                    <div>
                                                        <div className="small-title">
                                                            {meta_item}
                                                        </div>
                                                        <p className="margin_bottom_top">- {data_1}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Collapse>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
 
export default CVScan;