import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './Block3.css'
import Default from './Elements/CV/Default'
import CVSamples from './Elements/CVSamples'
import CVScan from './Elements/CV/CV_Scan'

class Block3 extends Component {
    render() {
        var cv;
        /* If this.props.cv_upload_data is null => <Default /> else <CV_Upload /> */
        if(this.props.cv_upload_data === null){
            cv = <Default />
        }
        else{
            cv = <CVScan file={this.props.file} cv_upload_data={this.props.cv_upload_data}/>
        }
        return (
            <div>
                <div className="block3-outter-div">
                    {cv}
                    <CVSamples/>
                </div>
            </div>
        );
    }
}
 
export default Block3;