import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Block4.css';

class FormRegister extends Component {
    render() { 
        return (
            <form className="block4-form-box" id="RegisterForm">
                <div className="block4-form">
                    <input className="block4-form-input" placeholder="Họ tên *" type="text"/>
                    <input className="block4-form-input" placeholder="Email *" type="text"/>
                    <input className="block4-form-input" placeholder="Điện thoại" type="text"/>
                    <input className="block4-form-input" placeholder="Địa chỉ doanh nghiệp (Tỉnh/thành phố)" type="text"/>
                    <textarea className="block4-form-textarea" name="" id="" cols="30" rows="3" placeholder="Note"></textarea>
                    <button className="block4-form-button  button-hover" type="button">Gửi</button>
                </div>
            </form>
        );
    }
}
 
export default FormRegister;