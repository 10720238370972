import React, {Component} from 'react';
import { Layout } from 'antd';
import './App.css'
import NavBarClass from './components/NavBar/NavBar';
import Block1 from './components/Body/Block1/Block1';
import Block2 from './components/Body/Block2/Block2';
import Block3 from './components/Body/Block3/Block3';
import Block4 from './components/Body/Block4/Block4';
import FooterBlock from './components/Footer/Footer';
import axios from 'axios'

const { Header, Content, Footer } = Layout;

class App extends Component {
    state = {
        error: null,
        file: null,
        cv_upload: null,
        default_cv: {
            education: {
                title: "Học vấn",
                content: [
                    {
                        time: "07/2015 - 11/2019",
                        title:"Đại học Kinh tế quốc dân",
                        content:[
                            "Chuyên ngành: Quản trị doanh nghiệp",
                            "Tốt nghiệp: loại giỏi, ĐTB: 3.4/4"
                        ]
                    },
                    {
                        time: "01/2020 - 11/2022",
                        title:"Đại học Ngoại thương",
                        content:[
                            "Thạc sỹ: chuyên ngành Dân tộc học"
                        ]
                    }
                ]
            },
            experience: {
                title: "Kinh nghiệm làm việc",
                content: [
                    {
                        time: "10/2013 - Present",
                        title: "ARCHITECT INTERN",
                        content: [
                            "Lên ý tưởng và thực hiện các công việc đồ họa cho dự án mới.",
                            "Lên thiết kế không gian và mặt bằng thi công, lên concept thiết kế, chất liệu để thảo luận với khách hàng.",
                            "Tham gia vào tất cả công đoạn thiết kế và xây dựng 6 dự án lớn trong và ngoài nước. ",
                            "Thực hiện thiết kế và lên bố cục để hỗ trợ khách hàng trong suốt dự án. Tỉ lệ thỏa mãn của khách hàng là hơ 95%.",
                            "Thực hiện 3D model, kết xuất đồ họa và video hoạt hinh."
                        ]
                    }
                ]
            },
            activity: {
                title: "Hoạt động",
                content:[
                    {
                        time: "10/2013 - Present",
                        title: "ARCHITECT INTERN",
                        content: [
                            "Thiếu niên nhi đồng giỏi.",
                            "Người mẫu 2020 "
                        ]
                    }
                ]
            },

            project: {
                title: "Dự án",
                content:[
                    {
                        time: "10/2017 - Present",
                        title: "The S HOUSE",
                        content: [
                            "Link: source.project",
                            `Hình thức, cấu trúc và tính năng cơ học của tòa nhà dược tích hợp vào trong một lớp vỏ duy nhất. Ví dụ, các cửa gió
                            cũng hoạt động như một màn hình đọc theo mặt tiền của toàn nhà.`
                        ]
                    }
                ]
            }
        }
    }

    handleInput = e => {
        let new_file = e.target.files[0];
        this.setState({file: new_file});
    }

    handleUpload = e => {
        var file = this.state.file;
        var formdata = new FormData();
        formdata.append("file", file)

        axios.post(
            "https://demo.computervision.com.vn/api/v2/cv_parser/cv_file_parser",
            formdata,
            {
                auth: {
                    username: "e37bafa70cd7411bb5be4df45cdbcc4e",
                    password: "4b4074d73e0b8efc2870d958c2faf8aa8bdcbae63cff2b5706ed1247bf576cd3"
                }
            }
        ).then(response => {
            if("Error" in response.data){
                alert(response.data.Error);
                return;
            }
            var result = response.data.result;
            this.setState({cv_upload: result})
        }).catch(error => {
            console.error(error)
            this.setState({cv_upload: null})
        });
    }

    convertDoc = doc_file => {
        const docxConverter = require('docx-pdf');
        docxConverter(doc_file, './output.pdf', (error, result) => {
            if(error){
                alert("Unable to display CV");
                return;
            }
            console.log(result);
        })
    }

    render() {
        return (
            <Layout className="layout body">
                <Header>
                    <NavBarClass/>
                    <span>{this.state.error}</span>
                </Header>
                <Content>
                    <Block1 />
                    <h1 className="demo-text-center"> Demo </h1>
                    <Block2 file={this.state.file} handleInput={this.handleInput} handleUpload={this.handleUpload} />
                    <Block3 cv_upload_data={this.state.cv_upload} file={this.state.file}/>
                    <Block4 />
                </Content>
                <Footer>
                    <FooterBlock />
                </Footer>
            </Layout>
        );
    }
}
 
export default App;