import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import cvs_logo from '../../../imgs/CVS_Logo.svg'

class Logo extends Component {
    state = {
        // eslint-disable-next-line
        img: cvs_logo,
        home_link: "https://www.computervision.com.vn/en/"
    }

    render() { 
        return (
            // eslint-disable-next-line 
            <img src={this.state.img} />
        );
    }
}
 
export default Logo;