import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import Logo from './Elements/Logo';
import LeftDiv from './Elements/LeftDiv';
import RightDiv from './Elements/RightDiv';

class Footer extends Component {
    render() { 
        return (
            <div>
                <div className="footer-outter-div">
                    <Logo />
                    <div className="footer-inner-div">
                        <LeftDiv />
                        <RightDiv />
                    </div>
                </div>
                <hr className="footer-line"/>
                <p className="footer-last-line">
                    Copyright ©2020 by Computer Vision Vietnam. All Rights Reserved
                </p>
            </div>
        );
    }
}
 
export default Footer;