import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../Footer.css';

class DivElement extends Component {
    render() { 
        return (
            <div className="footer-inner-div">
                <img className="footer-icon-right" src={this.props.image} alt="Hello"/>
                <div className="footer-right-p">
                    {this.props.content}                
                </div>
            </div>
        );
    }
}
 
export default DivElement;