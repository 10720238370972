import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import './Block2.css'
import Button from './Elements/Button'
import UploadFile from './Elements/UploadFile'

class Block2 extends Component {

    render() { 
        return (
            <form>
                <div className="block2-outter-div">
                    <UploadFile file={this.props.file} onInput={this.props.handleInput} />
                    <Button onUpload={this.props.handleUpload}/>
                </div>
            </form>
        );
    }
}
 
export default Block2;