import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Block1.css'
import image from '../../../../imgs/block1_button.svg'
import {Link} from 'react-scroll';

class LeftInnerDiv extends Component {
    state = {
        buttons: ["Computer Vision", "OCR"]
    }
    render() { 
        return (
            <div className="block1-left-div">
                <h1 className="block1-h1-left-div">CVChain</h1>
                <p className="block1-p-left-div">
                Là bộ công cụ trích xuất, quản lý thông tin từ CV với các định dạng hỗ trợ PDF, JPG, PNG, DOC, ...
                <br className="block1-line-breaker"/>
                CVChain giúp sàng lọc nhanh chóng và dễ dàng, xử lý với số lượng CV lớn, giúp tiết kiệm nhân lực, chi phí.
                </p>
                <div className="block1-inline">
                    {this.state.buttons.map((item, index) => {
                        return(
                            <div key={index} className="block1-curved-div">
                                {item}
                            </div>
                        )
                    })}
                </div>
                <div>
                    <img className="block1-button-image" src={image} alt="Button"/>
                </div>
                <div>
                    <button className="block1-button button-hover">
                        <Link to="RegisterForm" spy={true} smooth={true} offset={-400} duration={1000}>Đăng kí dùng thử</Link> 
                    </button>
                </div>
            </div>
        );
    }
}
 
export default LeftInnerDiv;