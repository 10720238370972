import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css';
import Logo from './Elements/Logo';
import MenuBar from './Elements/MenuBar';
import { Navbar } from 'react-bootstrap';

class NavBarClass extends Component {
    render() {
        return (
            <div>
                <Navbar className="navbar" bg="white" variant="light">
                    <Logo />
                    <MenuBar />
                </Navbar>
            </div>
        );
    }
}
 
export default NavBarClass;