import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Footer.css';
import location_img from '../../../imgs/location.svg';
import mail_img from '../../../imgs/mail.svg';
import phone_img from '../../../imgs/phone.svg';
import DivElement from './LeftDiv/Div';

class LeftDiv extends Component {
    state = {
        location: {
            image_uri: location_img,
            content: <p>
                        VP Hà Nội: Tầng 2 tháp A toà T6-08, đường Tôn Quang Phiệt, Bắc Từ Liêm, Hà Nội. 
                        <br className="break-line"/>
                        VP Hồ Chí Minh: Tầng 6, toà Sumikura, 18H Cộng Hòa, Phường 4, Q. Tân Bình, TP. Hồ Chí Minh.
                    </p>
        },
        mail: {
            image_uri: mail_img,
            content: <p className="footer-left-p">
                        sales@computervision.com.vn
                    </p>
        },
        phone: {
            image_uri: phone_img,
            content: <p className="footer-left-p">
                        088.66.25.220
                    </p>
        }
    }
    render() { 
        return (
            <div className="footer-text-div">
                {Object.keys(this.state).map((item, index) => {
                        return(
                            <DivElement key={index} image={this.state[item].image_uri} content={this.state[item].content}/>
                        )
                    })
                }
            </div>
        );
    }
}
 
export default LeftDiv;