import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Block2.css'

class Button extends Component {
    render() { 
        return (
            <div>
                <button type="button" className="block2-button  button-hover" onClick={e => {this.props.onUpload(e)}}>
                    Quét CV
                </button>
            </div>
        );
    }
}
 
export default Button;