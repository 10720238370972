import React, { useState } from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import pdf from './sample.pdf';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import './CV.css';
import 'bootstrap/dist/css/bootstrap.min.css'

const DisplayPDF = () => {
    var state = {
        default_cv: {
            education: {
                title: "Học vấn",
                content: [
                    {
                        time: "07/2015 - 11/2019",
                        title:"Đại học Kinh tế quốc dân",
                        content:[
                            "Chuyên ngành: Quản trị doanh nghiệp",
                            "Tốt nghiệp: loại giỏi, ĐTB: 3.4/4"
                        ]
                    },
                    {
                        time: "01/2020 - 11/2022",
                        title:"Đại học Ngoại thương",
                        content:[
                            "Thạc sỹ: chuyên ngành Dân tộc học"
                        ]
                    }
                ]
            },
            experience: {
                title: "Kinh nghiệm làm việc",
                content: [
                    {
                        time: "10/2013 - Present",
                        title: "ARCHITECT INTERN",
                        content: [
                            "Lên ý tưởng và thực hiện các công việc đồ họa cho dự án mới.",
                            "Lên thiết kế không gian và mặt bằng thi công, lên concept thiết kế, chất liệu để thảo luận với khách hàng.",
                            "Tham gia vào tất cả công đoạn thiết kế và xây dựng 6 dự án lớn trong và ngoài nước. ",
                            "Thực hiện thiết kế và lên bố cục để hỗ trợ khách hàng trong suốt dự án. Tỉ lệ thỏa mãn của khách hàng là hơ 95%.",
                            "Thực hiện 3D model, kết xuất đồ họa và video hoạt hinh."
                        ]
                    }
                ]
            },
            activity: {
                title: "Hoạt động",
                content:[
                    {
                        time: "10/2013 - Present",
                        title: "ARCHITECT INTERN",
                        content: [
                            "Thiếu niên nhi đồng giỏi.",
                            "Người mẫu 2020 "
                        ]
                    }
                ]
            },

            project: {
                title: "Dự án",
                content:[
                    {
                        time: "10/2017 - Present",
                        title: "The S HOUSE",
                        content: [
                            "Link: source.project",
                            `Hình thức, cấu trúc và tính năng cơ học của tòa nhà dược tích hợp vào trong một lớp vỏ duy nhất. Ví dụ, các cửa gió
                            cũng hoạt động như một màn hình đọc theo mặt tiền của toàn nhà.`
                        ]
                    }
                ]
            }
        }
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; 
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [open, setOpen] = useState(false);

    document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
    });

    var onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    var changePage = (offset) => {
        setPageNumber(previousPage => {
            return previousPage + offset
        });
    }

    var nextPage = () => {
        changePage(1);
    }

    var previousPage = () => {
        changePage(-1);
    }

    return(
        <div>
            <h2 className="block3-text">Ví dụ</h2>
            <div className="outter-div-cv">
                <div className="inner-div-display-cv">
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess} className="block3-cv-image">
                        <Page pageNumber={pageNumber} />
                        <div className="display-same-line">
                            <div className="text">
                                Page {pageNumber}
                            </div>
                            <div>
                                <button className="pdf-cv-demo-butt" type="button" disabled={pageNumber<=1} onClick={previousPage}> Previous </button>
                            </div>
                            <div>
                                <button className="pdf-cv-demo-butt" type="button" disabled={pageNumber>=numPages} onClick={nextPage}> Next </button>
                            </div>
                        </div>
                    </Document>
                </div>
                <div className="inner-div-display-result">
                    {Object.keys(state.default_cv).map((item, index) => {
                        var element = state.default_cv[item];
                        return(
                            <div>
                                <Button
                                    className="div-display-result display-result"
                                    onClick={() => setOpen(!open)}
                                    aria-controls={index}
                                    aria-expanded={open}>
                                        {element.title}
                                </Button>
                                <Collapse in={open}>
                                    <div id={index} className="collapse-div">
                                        {element.content.map((obj, index) => {
                                            return(
                                                <div key={index} className="border-div">
                                                    <div className="time-div">
                                                        {obj.time}
                                                    </div>
                                                    <div className="small-title">
                                                        {obj.title}
                                                    </div>
                                                    {obj.content.map(item => {
                                                        return(
                                                            <div>
                                                                - {item}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
 
export default DisplayPDF;