import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import cvs_logo from '../../../imgs/CVS_Logo.svg'

class Logo extends Component {
    state = {
        img: cvs_logo
     }
    render() { 
        return (
            <div>
                <img className="footer-logo" src={this.state.img} alt="Logo"/>
            </div>
        );
    }
}
 
export default Logo;