import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../NavBar.css'
import { Nav } from 'react-bootstrap';

class MenuBar extends Component {
    state = {
        links: [
            { 
                title: "Home",
                link: "https://www.computervision.com.vn/en/"
            },
            {
                title: "Services",
                link: "https://www.computervision.com.vn/en/dinh-danh-khach-hang"
            }
        ]
    }

    render() {
        return (
            <Nav className="margin-menu-nav-bar ml-auto">
                {this.state.links.map((item, index) => {
                    return(
                        <Nav.Link key={index} href={item.link} className="link-padding-right">
                            {item.title}
                        </Nav.Link>
                    )
                })}
            </Nav>
        );
    }
}
 
export default MenuBar;