import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../Block2.css'

class UploadFile extends Component {
    render() {
        var file_name;
        if(this.props.file === null){
            file_name = "Nhập link file hoặc tải lên CV";
        }
        else{
            file_name = this.props.file.name;
        }
        return (
            <div className="block2-upload-file" >
                <div className="block2-same-line">
                    <div className="block2-icon-upload">
                        File
                    </div>
                    <div className="block2-slash"> | </div>
                    <div className="block2-icon-upload">
                        {file_name}
                    </div>
                </div>
                <label className="block2-label">
                    <input type="file" name="file" onChange={(e) => this.props.onInput(e)} hidden /> 
                    Tải lên file
                </label>
            </div>
        );
    }
}
 
export default UploadFile;