import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Block1.css'
import LeftInnerDiv from './Elements/LeftInnerDiv'
import RightInnerDiv from './Elements/RightInnerDiv'

class Block1 extends Component {
    render() { 
        return (
            <div className="block1-cover-outter-div">
                <div className="block1-cover-inner-div">
                    <LeftInnerDiv />
                    <RightInnerDiv />
                </div>
            </div>
        );
    }
}
 
export default Block1;