import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Block4.css';
import FormRegister from './FormRegister'

class Content extends Component {
    render() { 
        return (
            <div>
                <h1 className="block4-text-title">Đăng kí dùng thử</h1>
                <div className="block4-curved-div">
                    Miễn phí cài đặt và dùng thử <b>15 ngày</b>
                </div>
                <div className="block4-text">
                <p style={{textAlignLast: 'center'}}>
                    CVS hỗ trợ cài đặt và cho phép các doanh thử dịch vụ CVChain 
                    trong vòng 15 ngày để dánh giá khách quan nhất về dịch vụ của 
                    chúng tôi trước khi áp dụng vào doanh nghiệp.
                </p>
                </div>
                <FormRegister />
            </div>
        );
    }
}
 
export default Content;